<template>
  <div class="relative grid w-fit place-content-center" dir="ltr" ref="containerRef">
    <div
      v-for="(item, index) in sortedData"
      :key="item.asset.symbol"
      :class="
        cn(
          'transaction-card col-start-1 row-start-1 flex h-fit flex-col rounded border bg-neutral-200 text-start',
          { 'z-1': index === 0 }
        )
      "
    >
      <div
        :class="
          cn('flex w-full items-start gap-4 rounded bg-white p-4', {
            'hover:bg-white/80': index > 0 && item.transactionType !== 'sell',
            'shadow-sm': item.transactionType === 'sell',
          })
        "
      >
        <UiSkeleton
          :loading="!isFetched"
          :classes="{
            container: 'w-10',
            line: 'mt-4 h-2',
            slot: 'mt-2 flex h-auto w-10 items-center gap-0.5',
          }"
        >
          <IconCaretDown class="mb-0.5 inline-flex" v-if="item.transactionType === 'sell'" />
          <h4 class="text-xs/5 font-medium">{{ item.transactionTypeDisplay }}</h4>
        </UiSkeleton>

        <div class="flex flex-1 flex-col gap-2.5">
          <UiInfo
            class="z-1"
            :loading="!isFetched"
            :title="`#${item.asset.rankOrder}&nbsp;&nbsp;${item.asset.symbol}`"
            :subtitle="item.asset.description"
            :image="item.asset.logo"
            :skeletons-repeat="2"
            has-image
            :image-size="24"
            clamp
            :classes="{
              image: 'size-6 border bg-white',
              wrapper: 'gap-3',
              title: 'text-xs/4 font-semibold',
              subtitle: 'line-clamp-1 text-xs/4 font-light',
              titlesWrapper: 'flex-col gap-0',
              skeleton: {
                container: 'gap-3',
                line: 'h-3 first:w-8',
              },
            }"
          />

          <hr />

          <div class="flex gap-1.5">
            <UiSkeleton
              :loading="!isFetched"
              :repeat="2"
              :classes="{
                container: 'pt-0.5',
                line: 'h-3 first:w-1/2',
                slot: 'flex flex-1 flex-col text-xs font-medium',
              }"
            >
              <span class="text-xxs text-muted-foreground">Quantity</span>
              <b class="text-xs/5 font-medium">{{ numberToFixed(item.quantity) }}</b>
            </UiSkeleton>

            <UiSkeleton
              :loading="!isFetched"
              :repeat="2"
              :classes="{
                container: 'pt-0.5',
                line: 'h-3 first:w-1/2',
                slot: 'flex flex-1 flex-col text-xs font-medium',
              }"
            >
              <span class="text-xxs text-muted-foreground">Price</span>
              <b class="text-xs/5 font-medium">{{ toCash(item.price, "$", 2) }}</b>
            </UiSkeleton>

            <UiSkeleton
              :loading="!isFetched"
              :repeat="2"
              :classes="{
                container: 'pt-0.5',
                line: 'h-3 first:w-1/2',
                slot: 'flex flex-1 flex-col text-xs font-medium',
              }"
            >
              <span class="text-xxs text-muted-foreground">P/L</span>
              <UiPositiveNegative
                class="w-fit text-xs/5"
                :value="item.profitLoss"
                :format="(v) => toCash(v, '$', 2)"
              />
            </UiSkeleton>
          </div>
        </div>
      </div>

      <div
        class="flex w-full flex-col gap-2.5 p-4 font-medium text-black/50"
        v-if="item.transactionType === 'sell'"
      >
        <UiSkeleton
          :loading="!isFetched"
          tag="span"
          :repeat="1"
          :classes="{
            container: 'pb-0.5',
            line: 'h-3 w-20',
            slot: '!text-xxs text-muted-foreground font-medium',
          }"
          >{{ dayjs.unix(item.buyTransaction?.transactionDate!).locale("en").format("MMMM DD, YYYY") }}
        </UiSkeleton>

        <UiSkeleton
          :loading="!isFetched"
          :repeat="1"
          :classes="{
            line: 'h-4',
            slot: 'flex items-center justify-between ps-2 text-xs',
          }"
        >
          <span>
            {{ item.buyTransaction?.transactionTypeDisplay }}
          </span>
          <span class="font-medium" v-if="item.buyTransaction?.quantityDisplay">
            {{ numberToFixed(item.buyTransaction?.quantityDisplay, 2) }}
          </span>
          <span class="font-medium">
            {{ toCash(item.buyTransaction?.price, "$", 2) }}
          </span>
          <span class="w-12 text-end"> - </span>
        </UiSkeleton>
      </div>
    </div>
    <!-- {{ data }} -->
  </div>
</template>

<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query"

import { StocksTransaction } from "@finq/stocks/types"

const dayjs = useDayjs()
const { isIntersected, containerRef } = useIsIntersected()

const { data, isFetched } = useQuery({
  enabled: isIntersected,
  queryKey: ["homepage.stocksTransactions"],
  queryFn: ({ signal }) =>
    StocksService.getPortfoliosTransactions({ signal, params: { portfolioType: "long10", size: 1 } }),

  placeholderData: () =>
    ({
      data: arrayInLength(2).map(
        (i) => ({ asset: {}, transactionType: i === 1 ? "buy" : "sell" }) as StocksTransaction
      ),
    }) as unknown as PaginationResponse<StocksTransaction[]>,
})

// sorting data where buy is first and sell is last
const sortedData = computed(() =>
  [...data.value?.data!].sort((a, b) => (a.transactionType === "buy" ? 1 : -1))
)
</script>

<style lang="scss" scoped>
.transaction-card {
  width: 17.1875rem;
  --offset-cards: 12px;

  &:nth-child(1) {
    margin-top: 3.7rem;
    transform: translateX(var(--offset-cards));
  }
  &:nth-child(2) {
    transform: translateX(calc(var(--offset-cards) * -1));
  }
}
</style>
